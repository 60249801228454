import 'jquery-ui/dist/jquery-ui.js';
import {Tiles} from './tiles.js';
import {Sparkline} from './sparkline.js';
import {ProjectMap} from '../projects/project_map.js';
import 'jquery-expander/jquery.expander.js';

Tiles.GranularFields = {
  init: function(data_url = null, common_data = {}) {
    data_url = data_url || document.location.href + '.json';
    Tiles.init(data_url, common_data, TGF.refresh_tool_links);
    return null;
  },
  update_element: function(object, options, file_upload) {
    var extended_options, form, hidden_field, key, value;
    options || (options = {});
    extended_options = $.extend(options, Tiles.common_data);
    Tiles.show_loading_indicator(object);
    if (file_upload) {
      form = $(object).closest('.replaceable').find('form');
      if ($(form).data('properties_added') !== true) {
        for (key in extended_options) {
          value = extended_options[key];
          hidden_field = $("<input/>", {
            type: 'hidden',
            name: key,
            value: value
          });
          hidden_field.prependTo(form);
        }
        $(form).data('properties_added', true);
        $(form).submit();
      }
    } else {
      $.ajax({
        type: 'PATCH',
        url: Tiles.data_url,
        data: extended_options,
        success: function(data) {
          return TGF.finish_element_update(data);
        },
        dataType: 'json'
      }).fail(function(xhr, text_status, thrown_error) {
        return null;
      });
    }
    return null;
  },
  finish_element_update: function(data) {
    TGF.replace_element(data);
    Tiles.hide_loading_indicator($("#" + data.section + "_tools"));
    return null;
  },
  replace_element: function(data) {
    $("#" + data.element_id).replaceWith(data.html);
    if (Tiles.replacement_callback != null) {
      Tiles.replacement_callback();
    }
    return null;
  },
  refresh_tool_links: function() {
    var j, len, ref, tool;
    $(document).on('keypress', ':input:not(textarea):not([type=submit])', function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
      return null;
    });
    ref = ['add', 'edit', 'save', 'delete', 'cancel'];
    for (j = 0, len = ref.length; j < len; j++) {
      tool = ref[j];
      $(".granular_field .tools ." + tool).each(function(i, obj) {
        if ($(obj).data('tool_click_added') !== true) {
          $(obj).click(function() {
            tool = $(obj).attr('title').toLowerCase();
            if (tool === "delete") {
              if (!confirm("Are you sure you want to delete this entry?")) {
                return false;
              }
            }
            return TGF.tool_clicked(this, tool);
          });
          $(obj).data('tool_click_added', true);
        }
        return null;
      });
    }
    $(".granular_field .tools .safe_save").each(function(i, obj) {
      if ($(obj).data('tool_click_added') !== true) {
        $(obj).click(function() {
          return false;
        });
        $(obj).dblclick(function() {
          tool = $(obj).attr('title').toLowerCase();
          return TGF.tool_clicked(this, tool);
        });
        $(obj).data('tool_click_added', true);
      }
      return null;
    });
    $(".writein").each(function(i, obj) {
      if ($(obj).data('tool_click_added') !== true) {
        $(obj).siblings('.cancel_container').children('.cancel').click(function() {
          $(this).parent().hide();
          $(this).parent().prevAll('.writein').first().hide().val("");
          $(this).parent().prevAll('select').show();
          return false;
        });
        $(obj).siblings('select').change(function() {
          if ($(this).val() === "Other") {
            $(this).hide();
            $(this).nextAll('.writein').first().show();
            $(this).nextAll('.cancel_container').first().show();
          }
          return null;
        });
        $(obj).data('tool_click_added', true);
      }
      return null;
    });
    $(".independent_select").each(function(i, obj) {
      if ($(obj).data('tool_click_added') !== true) {
        $(obj).change(function() {
          TGF.independent_select_change($(this).parent().attr('id'));
          return null;
        });
        $(obj).data('tool_click_added', true);
      }
      return null;
    });
    $(".replaceable input[type='file']").each(function(i, obj) {
      var at, container, container_id, form, iframe;
      container = $(obj).closest('.replaceable');
      container_id = container.attr('id');
      form = null;
      if (container.find('form').length === 0) {
        form = $("<form></form>", {
          id: container_id + "_form",
          enctype: 'multipart/form-data',
          target: container_id + "_frame",
          action: Tiles.data_url,
          method: 'post'
        });
        container.contents().appendTo(form);
        at = $('meta[name="csrf-token"]').attr('content');
        $("<input name='authenticity_token' type='hidden' value='" + at + "'>").appendTo(form);
        $("<input name='utf8' type='hidden' value='✓'>").appendTo(form);
        $("<input type='hidden' name='_method' value='patch'>").appendTo(form);
        form.appendTo(container);
      } else {
        form = container.find('form');
      }
      if (form.data('iframe_added') !== true) {
        form.prop('target', container_id + "_frame");
        form.data('iframe_added', true);
        iframe = $("<iframe></iframe>", {
          id: container_id + "_frame",
          name: container_id + "_frame"
        });
        iframe.css('display', 'none');
        iframe.appendTo(container);
        iframe.on('load', function() {
          var data, iframe_content;
          iframe_content = iframe.contents().text();
          if (iframe_content.length > 0) {
            data = $.parseJSON(iframe_content);
            TGF.finish_element_update(data);
          }
          return null;
        });
      }
      return null;
    });
    $(".map").each(function(i, obj) {
      ProjectMap.map_container = $(obj).closest('.collapsible');
      if ($(obj).data('map_initialized') !== true) {
        ProjectMap.reset();
        ProjectMap.reset_markers_from_dom();
        $(obj).data('map_initialized', true);
      }
      return null;
    });
    $(".map_location").each(function(i, obj) {
      return ProjectMap.reset_markers_from_dom();
    });
    $(".gllpLatlonPicker").each(function(i, obj) {
      if ($(obj).data('picker_initialized') !== true) {
        (new GMapsLatLonPicker()).init($(obj));
        $(obj).data('picker_initialized', true);
      }
      return null;
    });
    $('.hideable').expander({
      slicePoint: 250,
      expandText: 'More',
      userCollapseText: 'Less'
    });
    Sparkline.refreshSparklines();
    return null;
  },
  hide: function(e) {
    if ($(e).parents('.replaceable').length) {
      $(e).closest('.replaceable').find('.collapsible').hide();
    } else if ($(e).parents('.label').length) {
      $(e).closest('.label').siblings('.collapsible').hide();
    } else if ($(e).parents('label').length) {
      $(e).closest('label').siblings('.collapsible').hide();
    } else {
      $(e).closest('.tile').find('.collapsible').hide();
    }
    $(e).hide();
    $(e).siblings('.show').show();
    return false;
  },
  show: function(e) {
    if ($(e).parents('.replaceable').length) {
      $(e).closest('.replaceable').find('.collapsible').show();
    } else if ($(e).parents('.label').length) {
      $(e).closest('.label').siblings('.collapsible').show();
    } else if ($(e).parents('label').length) {
      $(e).closest('label').siblings('.collapsible').show();
    } else {
      $(e).closest('.tile').find('.collapsible').show();
    }
    $(e).hide();
    $(e).siblings('.hide').show();
    $.sparkline_display_visible();
    return false;
  },
  tool_clicked: function(object, tool) {
    var element_id, file_upload, values;
    element_id = $(object).closest('.replaceable').attr('id');
    values = {};
    file_upload = false;
    if (tool === 'save') {
      $(object).closest('.tile').find('input').each(function(id, obj) {
        switch ($(obj).attr('type')) {
          case 'text':
            if ($(obj).attr('name') != null) {
              values[$(obj).attr('name')] = $(obj).val();
            }
            break;
          case 'number':
            if ($(obj).attr('name') != null) {
              values[$(obj).attr('name')] = $(obj).val();
            }
            break;
          case 'password':
            if ($(obj).attr('name') != null) {
              values[$(obj).attr('name')] = $(obj).val();
            }
            break;
          case 'hidden':
            if ($(obj).attr('name') != null) {
              values[$(obj).attr('name')] = $(obj).val();
            }
            break;
          case 'url':
            if ($(obj).attr('name') != null) {
              values[$(obj).attr('name')] = $(obj).val();
            }
            break;
          case 'checkbox':
            if ($(obj).attr('name') != null) {
              if ($(obj).is(':checked')) {
                values[$(obj).attr('name')] = $(obj).val() || "true";
              } else {
                values[$(obj).attr('name')] = "false";
              }
            }
            break;
          case 'file':
            if (!!$(obj).val()) {
              file_upload = true;
            }
        }
        return null;
      });
      $(object).closest('.tile').find('select').each(function(id, obj) {
        if ($(obj).attr('name') != null) {
          values[$(obj).attr('name')] = $(obj).val();
        }
        return null;
      });
      $(object).closest('.tile').find('textarea').each(function(id, obj) {
        if ($(obj).attr('name') != null) {
          values[$(obj).attr('name')] = $(obj).val();
        }
        return null;
      });
    }
    TGF.update_element(object, {
      command: 'replace_element',
      tool: tool,
      element_id: element_id,
      values: JSON.stringify(values)
    }, file_upload);
    return false;
  },
  select_with_write_in_change_legacy: function(id) {
    var cancel, select, write_in;
    select = $("#" + id);
    write_in = $("#" + id.replace("_edit_list", "_write_in_box"));
    cancel = $("#" + id.replace("_edit_list", "_cancel"));
    if (select.val() === "Other") {
      select.hide();
      write_in.show();
      cancel.show();
    }
    return null;
  },
  select_write_in_cancel_legacy: function(id) {
    var cancel, select, write_in;
    select = $("#" + id.replace("_cancel", "_edit_list"));
    write_in = $("#" + id.replace("_cancel", "_write_in_box"));
    cancel = $("#" + id);
    select.val("");
    select.show();
    write_in.hide();
    cancel.hide();
    return null;
  },
  select_with_write_in_change: function(id, type) {
    var cancel, parent, select, write_in;
    parent = $("#" + id);
    select = parent.find(type + '_select');
    write_in = parent.find(type + '_write_in');
    cancel = parent.find(type + '_cancel');
    if (select.val() === "Other") {
      select.hide();
      write_in.show();
      cancel.show();
    }
    return null;
  },
  select_write_in_cancel: function(id, type) {
    var cancel, parent, select, write_in;
    parent = $("#" + id);
    select = parent.find(type + '_select');
    write_in = parent.find(type + '_write_in');
    cancel = parent.find(type + '_cancel');
    select.val("");
    select.show();
    write_in.hide();
    cancel.hide();
    return null;
  },
  select_dependency_settings: {},
  independent_select_change: function(id) {
    var category_select, category_value, category_write_in_box, dependent_select, dependent_value, dependent_write_in_box, independent_select, parent_object, selected_option, selected_value;
    parent_object = $("#" + id);
    independent_select = parent_object.find('.independent_select').first();
    selected_value = independent_select.val();
    if (!(selected_value === "" || selected_value === "Other")) {
      selected_option = independent_select.find("option:selected").first();
      category_value = selected_option.data('default-category');
      if (category_value !== null) {
        category_select = parent_object.find('.category_select').first();
        if (category_select.find("option[value=\"" + category_value + "\"]").length > 0) {
          TGF.select_write_in_cancel(id, "category");
          category_select.val(category_value);
        } else {
          category_write_in_box = parent_object.find('.category_write_in');
          category_select.val("").val("Other");
          category_write_in_box.val(category_value);
          TGF.select_with_write_in_change(id, "category");
        }
      }
      dependent_value = selected_option.data('default-dependent');
      if (dependent_value !== null) {
        dependent_select = parent_object.find('.dependent_select').first();
        if (dependent_select.find("option[value=\"" + dependent_value + "\"]").length > 0) {
          TGF.select_write_in_cancel(id, "dependent");
          dependent_select.val(dependent_value);
        } else {
          dependent_write_in_box = parent_object.find('.dependent_write_in');
          dependent_select.val("").val("Other");
          dependent_write_in_box.val(dependent_value);
          TGF.select_with_write_in_change(id, "dependent");
        }
      }
    }
    return null;
  }
};
export var TGF = Tiles.GranularFields;
