import {AutoComplete} from '../src/javascript/common/autocomplete.js';
import {DatePicker} from '../src/javascript/common/datepicker.js';
import {Tiles} from '../src/javascript/common/tiles.js';
import {TGF} from '../src/javascript/common/tiles.granular_fields.js';
import '../src/javascript/common/tiles.scss'
import '../src/javascript/common/tiles.granular_fields.scss'


$(document).on('click', '.tile .title .tools .show a', function(e) {
  e.preventDefault();
  Tiles.update_section(this, "edit");  return false;
}).on('click', '.tile .title .tools .edit a', function(e) {
  e.preventDefault();
  Tiles.update_section(this, "show");  return false;
}).on('click', '.tools_small a.hide', function(e) {
  e.preventDefault();
  return TGF.hide(this);
}).on('click', '.tools_small a.show', function(e) {
  e.preventDefault();
  return TGF.show(this);
}).on('click', '.tools_small a.show', function(e) {
  e.preventDefault();
  return TGF.show(this);
}).on('click', '.image_item_popup', function(e) {
  e.preventDefault();
  return Popup.begin(this);
}).on('click', '.add_fields', function(e) {
  var link, new_id, regexp;
  e.preventDefault();
  link = $(e.target).closest('a');
  new_id = new Date().getTime();
  regexp = new RegExp("new_" + link.data('association'), "g");
  link.closest('.field_set').before(link.data('fields').replace(regexp, new_id));
  return null;
}).on('click', '.remove_fields', function(e) {
  var container;
  e.preventDefault();
  container = $(e.target).closest('div.field_set');
  container.find('.destroy_checkbox').prop('checked', true);
  container.hide();
  return null;
});


$().ready(function() {
  AutoComplete.init();
  DatePicker.init();
  if (typeof tgf_url !== 'undefined') {Tiles.GranularFields.init(tgf_url);} else {Tiles.GranularFields.init();}
  if (document.querySelector(".tile")) {
    document.querySelectorAll(".tile").forEach(function(tile) {
      Tiles.reload_observer.observe(tile, { attributes: true });
    });
  }
});
