import dotBlended from '../../../images/DotBlended.png';
import dotOlive from '../../../images/DotOlive.png';
import dotOliveOnOrange from '../../../images/DotOliveOnOrange.png';
import dotOrange from '../../../images/DotOrange.png';
import dotOrangeOnOlive from '../../../images/DotOrangeOnOlive.png';

export var ProjectMap = {
  map_container: null,
  map_div: null,
  map: null,
  min_zoom: null,
  default_zoom: null,
  default_center: null,
  markers: [],
  marker_data: [],
  marker_clusterer: null,
  cluster_markers: false,
  info_window: null,
  icons: {},
  reset: function() {
    ProjectMap.clear_markers();
    ProjectMap.map_div = null;
    ProjectMap.map = null;
    return ProjectMap.cluster_markers = false;
  },
  initialize_map: function() {
    var map_options;
    ProjectMap.icons = {
      dot_blended: {
        url: dotBlended, scaledSize: new google.maps.Size(8, 8), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(4, 4)
      },
      dot_olive: {
        url: dotOlive, scaledSize: new google.maps.Size(8, 8), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(4, 4)
      },
      dot_olive_on_orange: {
        url: dotOliveOnOrange, scaledSize: new google.maps.Size(8, 8), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(4, 4)
      },
      dot_orange: {
        url: dotOrange, scaledSize: new google.maps.Size(8, 8), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(4, 4)
      },
      dot_orange_on_olive: {
        url: dotOrangeOnOlive, scaledSize: new google.maps.Size(8, 8), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(4, 4)
      }
    };
    if (ProjectMap.map === null) {
      ProjectMap.min_zoom || (ProjectMap.min_zoom = 2);
      ProjectMap.default_zoom || (ProjectMap.default_zoom = 3);
      ProjectMap.default_center || (ProjectMap.default_center = new google.maps.LatLng(38, -96));
      map_options = {
        zoom: ProjectMap.default_zoom,
        minZoom: ProjectMap.min_zoom,
        center: ProjectMap.default_center,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false
      };
      ProjectMap.map_div = $(ProjectMap.map_container).find('.map')[0];
      ProjectMap.map = new google.maps.Map(ProjectMap.map_div, map_options);
    }
    return null;
  },
  clear_markers: function() {
    var j, len, marker, ref;
    if (ProjectMap.cluster_markers && typeof MarkerClusterer !== 'undefined' && !!ProjectMap.marker_clusterer) {
      ProjectMap.marker_clusterer.clearMarkers();
    }
    ref = ProjectMap.markers;
    for (j = 0, len = ref.length; j < len; j++) {
      marker = ref[j];
      marker.setMap(null);
    }
    ProjectMap.markers.length = 0;
    return ProjectMap.marker_data.length = 0;
  },
  reset_markers_from_dom: function() {
    var data;
    data = [];
    $(ProjectMap.map_container).find(".map_location").each(function(i, obj) {
      var point;
      point = {
        latitude: $(obj).data('latitude'),
        longitude: $(obj).data('longitude'),
        title: '' + $(obj).data('label'),
        detail: '' + $(obj).data('detail')
      };
      return data.push(point);
    });
    ProjectMap.reset_markers_from_data(data);
    return null;
  },
  reset_markers_from_data: function(data) {
    var consolidated_data, i, j, key, marker, mhash, point, position, ref;
    ProjectMap.initialize_map(ProjectMap.map_container);
    ProjectMap.clear_markers();
    consolidated_data = {};
    for (i = j = 0, ref = data.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
      point = data[i];
      key = point.latitude + ":" + point.longitude;
      if (consolidated_data[key] != null) {
        if (consolidated_data[key].label.split(', ').indexOf(point.label) === -1) {
          consolidated_data[key].label += ', ' + point.label;
          consolidated_data[key].detail += "<br alt='' />" + point.detail;
        }
      } else {
        consolidated_data[key] = point;
      }
    }
    i = 0;
    for (key in consolidated_data) {
      point = consolidated_data[key];
      ProjectMap.marker_data.push(point);
      position = new google.maps.LatLng(point.latitude, point.longitude);
      mhash = {
        position: position,
        map: ProjectMap.map,
        title: point.label,
        icon: ProjectMap.icons.dot_olive
      };
      if ((consolidated_data[key].detail && consolidated_data[key].detail.includes('alt=')) || (consolidated_data[key].ids && consolidated_data[key].ids.includes('|'))) {
        mhash.icon = ProjectMap.icons.dot_orange;
      }
      marker = new google.maps.Marker(mhash);
      google.maps.event.addListener(marker, 'click', (function(i) {
        return function() {
          return ProjectMap.show_info_window(i);
        };
      })(i));
      ProjectMap.markers.push(marker);
      i++;
    }
    if (ProjectMap.cluster_markers && typeof MarkerClusterer !== 'undefined') {
      ProjectMap.marker_clusterer = new MarkerClusterer(ProjectMap.map, ProjectMap.markers, {
        averageCenter: true,
        gridSize: 25,
        minimumClusterSize: 10,
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      });
    }
    ProjectMap.refresh_map();
    return null;
  },
  show_info_window: function(i) {
    if (!ProjectMap.info_window) {
      ProjectMap.info_window = new google.maps.InfoWindow();
    }
    if (ProjectMap.marker_data[i].detail) {
      ProjectMap.info_window.setContent(ProjectMap.marker_data[i].detail);
      ProjectMap.info_window.open(ProjectMap.map, ProjectMap.markers[i]);
    } else if (ProjectMap.marker_data[i].ids) {
      $.ajax({
        type: 'get',
        url: "/projects/project_locations",
        data: {
          ids: ProjectMap.marker_data[i].ids
        },
        success: function(data) {
          ProjectMap.info_window.setContent(data);
          return ProjectMap.info_window.open(ProjectMap.map, ProjectMap.markers[i]);
        }
      }).fail(function(xhr, text_status, thrown_error) {
        alert("There was a problem...");
        alert(text_status);
        alert(thrown_error);
        return null;
      });
    }
    return null;
  },
  refresh_map: function(hide_if_empty) {
    var j, lat_long_bounds, len, marker, ref;
    ProjectMap.initialize_map(ProjectMap.map_container);
    $(ProjectMap.map_container).closest('.tile').find('.loading').show();
    if (ProjectMap.markers.length > 0) {
      $(ProjectMap.map_div).show();
      google.maps.event.trigger(ProjectMap.map, 'resize');
      if (ProjectMap.markers.length === 1) {
        ProjectMap.map.setZoom(14);
        ProjectMap.map.setCenter(ProjectMap.markers[0].getPosition());
      } else {
        lat_long_bounds = new google.maps.LatLngBounds();
        ref = ProjectMap.markers;
        for (j = 0, len = ref.length; j < len; j++) {
          marker = ref[j];
          lat_long_bounds.extend(marker.position);
        }
        ProjectMap.map.fitBounds(lat_long_bounds);
      }
    } else {
      if (hide_if_empty) {
        $(ProjectMap.map_div).hide();
      } else {
        ProjectMap.map.setZoom(ProjectMap.default_zoom);
        ProjectMap.map.setCenter(ProjectMap.default_center);
      }
    }
    $(ProjectMap.map_container).closest('.tile').find('.loading').hide();
    return null;
  }
};
