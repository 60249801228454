export const AutoComplete = {
  init: function() {
    AutoComplete.enableAutocomplete();
    AutoComplete.mutationObserver.observe(document.querySelector("#Content"), { subtree: true, childList: true });
  },

  enableAutocomplete: function() {
    $('.autocomplete').each(function(index, element) {
      var autocompleteUrl = $(element).data('autocomplete-url');
      var minLength = $(element).data('autocomplete-min-length') || 2;
      var delay = $(element).data('autocomplete-delay') || 500;
      var enabled = $(element).data('autocomplete-enabled');
      if (autocompleteUrl !== null && enabled !== true) {
        $(element).autocomplete({
          source: function(request, response) {
            var element = $(document.activeElement);
            var term = request.term;
            if (term.length >= minLength) {
              var params = {term: term};
              if (element.data('autocomplete-with')) {    // Include other field values in autocomplete request
                var ref = element.data('autocomplete-with').split(',');
                for (i = 0, len = ref.length; i < len; i++) {
                  var field = ref[i];
                  field = field.replace(/^\s+|\s+$/g, "");
                  params[field] = element.parent().find("input[name=" + field + "]").val();
                }
              }
              return $.getJSON(element.data('autocomplete-url'), params, response);
            }
          },
          minLength: minLength,
          delay: delay
        });
        $(element).data('autocomplete-enabled', true);
      }
    });

    $('.autocomplete_multiple').each(function(index, element) {
      var autocompleteUrl = $(element).data('autocomplete-url');
      var minLength = $(element).data('autocomplete-min-length') || 2;
      var delay = $(element).data('autocomplete-delay') || 500;
      var enabled = $(element).data('autocomplete-enabled');
      if (autocompleteUrl !== null && enabled !== true) {
        $(element).autocomplete({
          source: function(request, response) {
            var i, len;
            var element = $(document.activeElement);
            var term = request.term.split(/;\s*/).pop();
            if (term.length >= minLength) {
              var params = {term: term};
              if (element.data('autocomplete-with')) {    // Include other field values in autocomplete request
                var ref = element.data('autocomplete-with').split(',');
                for (i = 0, len = ref.length; i < len; i++) {
                  var field = ref[i];
                  field = field.replace(/^\s+|\s+$/g, "");
                  params[field] = element.parent().find("input[name=" + field + "]").val();
                }
              }
              return $.getJSON(element.data('autocomplete-url'), params, response);
            }
          },
          focus: function() {
            return false;
          },
          select: function(event, ui) {
            var terms;
            terms = this.value.split(/;\s*/);
            terms.pop();
            terms.push(ui.item.value);
            terms.push("");
            this.value = terms.join("; ");
            return false;
          },
          minLength: minLength,
          delay: delay
        });
        $(element).data('autocomplete-enabled', true);
      }
    });
  },

  mutationObserver: new MutationObserver(function(mutationsList) {
    var reenableAutocomplete = false;
    mutationsList.forEach(function(mutation) {
      mutation.addedNodes.forEach(function(addedNode) {
        if($(addedNode).find('.autocomplete, .autocomplete_multiple')) {
          reenableAutocomplete = true;
        }
      });
    });
    if (reenableAutocomplete) {AutoComplete.enableAutocomplete();}
  })
}
