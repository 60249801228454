export const DatePicker = {
  init: function() {
    DatePicker.enableDatePicker();
    DatePicker.mutationObserver.observe(document.querySelector("#Content"), { subtree: true, childList: true });
  },
  
  enableDatePicker: function() {
    $('.datepicker').each(function(index, element) {
      var enabled = $(element).data('datepicker-enabled');
      if (enabled !== true) {
        $(element).datepicker({
          dateFormat: 'yy/mm/dd',
          showOtherMonths: true,
          selectOtherMonths: true,
          showButtonPanel: true,
          beforeShow: function(input, inst) {
            $('.datepicker_current_input').removeClass('datepicker_current_input');
            $(input).addClass('datepicker_current_input');
            return setTimeout(function() {
              var top = $(".datepicker_current_input").offset().top + 35;
              if ($('#Popup').is(':visible')) {top -= $(document).scrollTop();}
              var left = $(".datepicker_current_input").offset().left;
              inst.dpDiv.css({top: top, left: left});
              return 0;
            });
          }
        });
        $(element).data('datepicker-enabled', true);
      }
    });
  },

  mutationObserver: new MutationObserver(function(mutationsList) {
    var reenableDatePicker = false;
    mutationsList.forEach(function(mutation) {
      mutation.addedNodes.forEach(function(addedNode) {
        if($(addedNode).find('.datepicker')) {
          reenableDatePicker = true;
        }
      });
    });
    if (reenableDatePicker) {DatePicker.enableDatePicker();}
  })
}
