export var Tiles = {
  data_url: null,
  common_data: {authenticity_token: $('meta[name=csrf-token]').attr('content')},
  replacement_callback: null,
  init: function(data_url = null, common_data = {}, replacement_callback) {
    Tiles.data_url = data_url || document.location.href + '.json';
    Tiles.common_data = $.extend(common_data, Tiles.common_data);
    Tiles.replacement_callback = replacement_callback;
    if (Tiles.replacement_callback != null) {
      Tiles.replacement_callback();
    }
    return null;
  },
  show_loading_indicator: function(object) {
    $(object).closest('.tools').find('.loading').show();
    return null;
  },
  hide_loading_indicator: function(object) {
    $(object).closest('.tools').find('.loading').hide();
    return null;
  },
  show_error_indicator: function(object) {
    $(object).closest('.tools').find('.error').show();
    return null;
  },
  update_section: function(object, mode) {
    var content, section;
    if (Tiles.data_url != null) {
      content = $(object).closest('.tile').find('.content');
      section = content.data("section");
      if (mode === 'show') {
        if (content.find('.replaceable').length === 1) {
          if (content.find('.replaceable > .tools > .save').length === 1) {
            content.find('.replaceable > .tools > .save').click();
            Tiles.show_loading_indicator(object);
            setTimeout("$('#" + section + "_tools').find('.edit a').click();", 2000);
            return;
          }
        }
      }
      Tiles.show_loading_indicator(object);
      $.get(Tiles.data_url, $.extend({section: section, mode: mode}, Tiles.common_data), function(data) {
        Tiles.replace_section(data);
        if (data.edit_mode) {
          if ($("#" + data.section + "_tile_content").find('.replaceable').length === 1) {
            $("#" + data.section + "_tile_content").find('.replaceable > .tools > .edit').click();
          }
        }
        Tiles.hide_loading_indicator($("#" + data.section + "_tools"));
        return null;
      }, 'json').fail(function(xhr, text_status, thrown_error) {
        Tiles.hide_loading_indicator(object);
        Tiles.show_error_indicator(object);
        return null;
      });
      return null;
    } else {
      alert("Not enabled (you shouldn't be seeing this)");
      return null;
    }
  },
  replace_section: function(data) {
    if (data.edit_mode) {
      $("#" + data.section + "_tools .show").hide();
      $("#" + data.section + "_tools .edit").show();
    } else {
      $("#" + data.section + "_tools .show").show();
      $("#" + data.section + "_tools .edit").hide();
    }
    $("#" + data.section + "_tile .content").html(data.html);
    if (Tiles.replacement_callback != null) {
      Tiles.replacement_callback();
    }
    return null;
  },
  reload_all_sections: function() {
    $('.tile').each(function(index, element) {
      var mode, section;
      section = element.id.replace("_tile", "");
      mode = $("#" + section + "_tools .edit").is(':visible') ? 'edit' : 'show';
      Tiles.update_section(element, mode);
      Tiles.enable_auto_complete();
      return null;
    });
    return null;
  },
  tile_last_reloaded: {},
  reload_observer: new window.MutationObserver(function(mutations_list) {
    mutations_list.forEach(function(mutation_record) {
      var obj = $(mutation_record.target);
      var id = obj.attr('id');
      if (Tiles.tile_last_reloaded[id] != obj.attr('needs-reload')) {
        Tiles.update_section('#'+id);
        Tiles.tile_last_reloaded[id] = obj.attr('needs-reload');
      }
    });
  })
};
